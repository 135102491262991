@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /**/

  /* This won't be included in your compiled CSS unless you actually use it */

  /* size  */
  .btn-sm {
    @apply py-1.5 px-5 text-sm font-medium;
  }

  .btn-base {
    @apply py-2 px-5 text-base font-medium;
  }

  .btn-lg {
    @apply py-2.5 px-5 text-lg font-medium;
  }

  /* normal  */
  .btn-danger {
    @apply inline-block bg-red-600 text-white leading-tight rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out;
  }
  .btn-primary {
    @apply inline-block bg-blue-600 text-white leading-tight rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out;
  }
  .btn-warning {
    @apply inline-block bg-yellow-600 text-white leading-tight rounded shadow-md hover:bg-yellow-700 hover:shadow-lg focus:bg-yellow-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-800 active:shadow-lg transition duration-150 ease-in-out;
  }
  .btn-success {
    @apply inline-block bg-green-600 text-white leading-tight rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out;
  }
  .btn-secondary {
    @apply inline-block bg-slate-600 text-white leading-tight rounded shadow-md hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-slate-800 active:shadow-lg transition duration-150 ease-in-out;
  }

  /* outline  */
  .btn-outline-danger {
    @apply inline-block border-2 border-red-600 text-red-600 hover:border-red-700 hover:text-white  hover:bg-red-700 leading-tight rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out;
  }
  .btn-outline-primary {
    @apply inline-block border-2 border-blue-600 text-blue-600 hover:border-blue-700 hover:text-white  hover:bg-blue-700 leading-tight rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out;
  }
  .btn-outline-warning {
    @apply inline-block border-2 border-yellow-600 text-yellow-600 hover:border-yellow-700 hover:text-white  hover:bg-yellow-700 leading-tight rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out;
  }
  .btn-outline-success {
    @apply inline-block border-2 border-green-600 text-green-600 hover:border-green-700 hover:text-white  hover:bg-green-700 leading-tight rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out;
  }
  .btn-outline-secondary {
    @apply inline-block border-2 border-slate-600 text-slate-600 hover:border-slate-700 hover:text-white  hover:bg-slate-700 leading-tight rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out;
  }

  .editor-icon-btn {
    @apply duration-200 hover:bg-gray-200 rounded-full w-8 h-8  text-ui-text-heading flex items-center justify-center cursor-pointer;
  }
  .editor-icon-btn.active {
    @apply bg-gray-200;
  }

  .modal-heading {
    @apply text-[20px] font-medium text-ui-text-heading;
  }

  .editor-input-box {
    @apply p-2 px-5 rounded-full bg-ui-light-white text-[14px] leading-[22px];
  }
}

/* loader */

body {
  @apply font-body;
  overflow-x: hidden;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @apply font-heading;
}

.dashboard-ui-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.dashboard-ui-scrollbar::-webkit-scrollbar-track {
  background: white;
  border-radius: 5px;
}

.dashboard-ui-scrollbar::-webkit-scrollbar-thumb {
  background-color: #f1f3f4;
  border-radius: 5px;
  border: none;
}

#webpack-dev-server-client-overlay {
  display: none !important;
}
