/* react toastify css  */
@import url("react-toastify/dist/ReactToastify.css");
@import url("../node_modules/react-responsive-carousel/lib/styles/carousel.min.css");

/* Custom Animation for Toast */
.toast-animated {
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-30%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.toast-fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-30%, 0, 0);
  }
}

.toast-fadeOutLeft {
  animation-name: fadeOutLeft;
}

.grigora-custom-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c8c9ca;
    border-radius: 5px;
    border: none;
  }
}

.grigora-custom-scrollbar-dark {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: rgb(63, 63, 63);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #777777;
    border: none;
  }
}

.grigora-hidden-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.g-toastify {
  background-color: white;
  border-radius: 21px;
  font-size: 14px;
  width: 250px;
  padding-top: 5px;
  padding-bottom: 5px;
  // width: fit-content;
  // min-height: 18px;

  // &.fadeInAnimationReactToastify {
  //   animation: fadeIn ease 0.5s;

  //   @keyframes fadeIn {
  //     0% {
  //       opacity: 0;
  //       transform: translateY(20%);
  //     }

  //     100% {
  //       opacity: 1;
  //       transform: translateY(0%);
  //     }
  //   }
  // }

  // &.fadeOutAnimationReactToastify {
  //   animation: fadeOut ease 0.5s;
  //   visibility: hidden;
  //   @keyframes fadeOut {
  //     0% {
  //       opacity: 1;
  //     }

  //     100% {
  //       opacity: 0;
  //     }
  //   }
  // }

  .Toastify__toast-body {
    // Vertical padding 0
    padding-top: 0;
    padding-bottom: 0;
    min-height: 50px;
  }

  .Toastify__toast-icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Toastify__close-button {
    align-self: normal;
    margin-right: 8px;
  }

  .Toastify__close-button svg {
    width: 15px;
    height: 15px;
  }
}

.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;

  &.infinite {
    animation-iteration-count: infinite;
  }
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

.grigora-action-btn {
  color: white;
  border-radius: 21px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;
  @apply bg-ui-green;

  &.small {
    padding: 5px 10px;
    font-size: 12px;
  }

  &.inactive {
    cursor: default;
    @apply bg-opacity-50;
  }

  &:not(.inactive):hover {
    @apply bg-ui-greenDark;
  }

  &.white {
    color: unset;
    @apply bg-ui-white;

    &.inactive {
      cursor: default;
      @apply bg-opacity-50;
    }

    &:not(.inactive):hover {
      @apply bg-ui-whiteDark;
    }
  }

  &.red {
    @apply bg-ui-red;

    &.inactive {
      cursor: default;
      @apply bg-opacity-50;
    }

    &:not(.inactive):hover {
      @apply bg-ui-redDark;
    }
  }

  &.blue {
    @apply bg-ui-og-blue;

    &.inactive {
      cursor: default;
      @apply bg-opacity-50;
    }

    &:not(.inactive):hover {
      @apply bg-ui-og-blue-dark;
    }
  }

  &.transparent {
    color: black;
    @apply bg-transparent;

    &.inactive {
      cursor: default;
      @apply bg-opacity-50;
    }

    &:not(.inactive):hover {
      @apply bg-gray-100;
    }
  }
}
